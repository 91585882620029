import ParentService from '@/service/ParentService.js';

export default class CategoryService extends ParentService {
   list() {
      const url = `${super.baseUrl}category`;
      return super.customGet(url, {});
   }

   add(dto) {
      const url = `${super.baseUrl}category`;
      return super.customPost(url, dto);
   }

   get(id) {
      const url = `${super.baseUrl}category/${id}`;
      return super.customGet(url);
   }

   update(id, dto) {
      const url = `${super.baseUrl}category/${id}`;
      return super.customPut(url, dto);
   }

   getAll() {
      const url = `${super.baseUrl}category/all`;
      return super.customGet(url);
   }

   export() {
      const url = `${super.baseUrl}category/export`;
      return super.customGetDownload(url);
   }

   delete(id) {
      const url = `${super.baseUrl}category/${id}`;
      return super.customDelete(url);
   }
}
