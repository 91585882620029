import Vue from 'vue';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies';
import VueMoment from 'vue-moment';
import panZoom from 'vue-panzoom';
import VueKonva from 'vue-konva';
import VueMixpanelBrowser from 'vue-mixpanel-browser';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import '@/assets/css/fonts.css';
import '@/assets/css/styles.css';
import '@mdi/font/css/materialdesignicons.min.css';
import 'normalize.css/normalize.css';
import store from '@/store';
import router from '@/router';
import '@/sass/overrides.sass';
import Auth from '@/auth2';
import Util from './utils';

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";

// import { getMessaging } from "firebase/messaging";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA14JtKTuUsSeVQwpynnc4xdoUbpM5YsqM",
//   authDomain: "metabim-7f255.firebaseapp.com",
//   projectId: "metabim-7f255",
//   storageBucket: "metabim-7f255.appspot.com",
//   messagingSenderId: "761622014985",
//   appId: "1:761622014985:web:ebad860bdc7f1461c25cd8",
//   measurementId: "G-VCEH2SGT4B"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


// // Initialize Firebase Cloud Messaging and get a reference to the service
// const messaging = getMessaging(app);
export const bus = new Vue();

Vue.use(VueCookies);
Vue.use(VueResource);

if (!process.env.VUE_APP_IS_DEBUG) {
   window.console.log = () => { };
}

Vue.use(VueMoment);
Vue.use(panZoom);

Vue.use(VueKonva);

Vue.use(VueMixpanelBrowser, {
   token: '362825c8dbc0b6f6b515cac459f01fd0',
   config: {
      debug: true,
      ignore_dnt: true,
   },
});
Vue.use(VueGtag, {
   config: {
      id: 'G-CQB5M4DMD9',
      params: {
         send_page_view: false,
      },
   },
});
export const auth = new Auth(router);
export const currentVue = new Vue({
   router,
   store,
   vuetify,
   render: (h) => h(App),
}).$mount('#app');

router.afterEach((to) => {
   console.log(to.name);
   Util.sendEvent( to.name, 'page_view', to.name , to);
});
