import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import app from './modules/app';
import lists from './modules/lists';
import forms from './modules/forms';
import auth from './modules/auth';
import tagsView from './modules/tagsView';
import systemView from './modules/systemView';
import pointcloudView from './modules/pointcloudView';
import globalGetters from './getters';
import tags from './modules/tags';

const getters = {
   ...globalGetters,
   ...app.getter,
   ...lists.getter,
   ...forms.getter,
   ...systemView.getter,
   ...tags.getter,
   ...pointcloudView.getter,
};
Vue.use(Vuex);

const store = new Vuex.Store({
   modules: {
      app,
      auth,
      tagsView,
      lists,
      forms,
      systemView,
      pointcloudView,
      tags,
   },
   getters,
   plugins: [createPersistedState({
      storage: window.sessionStorage,
   })],

});

export default store;
