import ParentService from '@/service/ParentService.js';

export default class SystemService extends ParentService {
   list(id) {
      const url = `${super.baseUrl}system/site/${id}`;
      return super.customGet(url);
   }

   export() {
      let url = `${super.baseUrl}system/export`;
      return super.customGetDownload(url);
   }

   add(dto) {
      let url = `${super.baseUrl}system`;
      return super.customPost(url, dto);
   }

   update(id, dto) {
      let url = `${super.baseUrl}system/${id}`;
      return super.customPut(url, dto);
   }
}
