const app = {
   state: () => ({
      message: '',
      messageType: 'success',
      site: null,
      activeListComp: null,
      currentSystem: null,
      confirmVisible: false,
      confirmData: {
         title: '',
         message: '',
         onConfirm: () => {},
         onCancel: () => {},
      },
   }),

   mutations: {
      setMessage(state, message) {
         state.message = message;
      },
      setMessageType(state, messageType) {
         state.messageType = messageType;
      },
      setSite: (state, siteData) => {
         state.site = siteData;
      },
      setActiveListComp: (state, compName) => {
         state.activeListComp = compName;
      },
      setCurrentSystem: (state, systemObj) => {
         state.currentSystem = systemObj;
      },
      setConfirmVisible: (state, isVisible) => {
         state.confirmVisible = isVisible;
      },
      setConfirmData: (state, data) => {
         state.confirmData = data;
      },
   },

   actions: {
      alertUser(state, message) {
         state.commit('setMessageType', message.type);
         state.commit('setMessage', message.message);
      },
      setSite(state, siteData) {
         state.commit('setSite', siteData);
         // update all data rlated to the site
         state.dispatch('updatePointClouds');
         state.dispatch('updateCategories');
         state.dispatch('updateSystems');
         state.dispatch('updateTagTree');
      },
      setActiveListComp(state, compName) {
         if (compName === state.activeListComp) {
            state.commit('setActiveListComp', null);
         } else {
            state.commit('setActiveListComp', null);
            state.commit('setActiveListComp', compName);
         }
      },
      resetActiveListComp(state) {
         state.commit('setActiveListComp', null);
      },
      setCurrentSystem(state, systemObj) {
         state.commit('setCurrentSystem', null);
         state.commit('setCurrentSystem', systemObj);
         setTimeout(() => {
            state.dispatch('updateTags');
         }, 100); // wait for the system to be set
      },
      resetApp(state) {
         state.commit('setCurrentSystem', null);
         state.commit('setCurrentPointCloud', null);
         state.commit('setActiveListComp', null);
         state.commit('setSite', null);
         state.commit('setIframeUrl', '');
         state.dispatch('app_resetConfirmDialog');
      },
      app_setConfirmVisible(state, isVisible) {
         state.commit('setConfirmVisible', isVisible);
      },
      app_setConfirmData(state, data) {
         state.commit('setConfirmData', data);
      },
      app_resetConfirmDialog(state) {
         state.commit('setConfirmVisible', false);
         setTimeout(() => {
            state.commit('setConfirmData', {
               title: '',
               message: '',
               onConfirm: () => {},
               onCancel: () => {},
            });
         }, 800);
      },
   },
   getter: {
      message: (state) => state.app.message,
      messageType: (state) => state.app.messageType,
      site: (state) => state.app.site,
      activeListComp: (state) => state.app.activeListComp,
      currentSystem: (state) => state.app.currentSystem,
      app_confirmVisible: (state) => state.app.confirmVisible,
      app_confirmData: (state) => state.app.confirmData,
   },
};

export default app;
