var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"color":"bg","mini-variant-width":"77","floating":"","mini-variant":true,"height":"100%","permanent":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-toolbar',{staticClass:"flex",attrs:{"elevation":"0","color":"bg","height":"68"}},[_c('v-img',{staticClass:"mblogo2",attrs:{"src":require('@/assets/' + _vm.img_showing),"max-height":50,"max-width":76,"width":50,"contain":"","position":"center center"}})],1),(_vm.showNavbar)?_c('v-list',{attrs:{"dense":"","flat":""}},[_c('v-list-item-group',{on:{"change":_vm.showListComp},model:{value:(_vm.navList),callback:function ($$v) {_vm.navList=$$v},expression:"navList"}},[_c('v-list-item',{attrs:{"value":"PointCloudList"}},[_c('v-list-item-icon',{staticClass:"my-5 mx-0 pa-0"},[_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cube-scan")])]}}],null,false,3653077803)},[_c('span',[_vm._v("Models")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Show models")])],1)],1),_c('v-list-item',{attrs:{"value":"SystemList"}},[_c('v-list-item-icon',{staticClass:"my-5 mx-0 pa-0"},[_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-sitemap")])]}}],null,false,3888910303)},[_c('span',[_vm._v("Systems")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Show systems")])],1)],1),(_vm.showTagsIcon)?_c('v-list-item',{attrs:{"value":"TagList"}},[_c('v-list-item-icon',{staticClass:"my-5 mx-0 pa-0"},[_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v("mdi-tag-multiple")])]}}],null,false,3175681895)},[_c('span',[_vm._v("Tags")])])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Show Tags")])],1)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }