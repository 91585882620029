const auth = {
   state: {
      isLogged: false,
      token: '',
      user: '',
   },
   mutations: {
      setToken(state, payload) {
         state.token = payload;
      },
      setUser(state, payload) {
         state.user = payload;
      },
      setIsLogged(state, payload) {
         state.isLogged = payload;
      },
   },
   actions: {
      updateAuth(state) {
         state.commit(
            'setToken',
            localStorage.getItem('token') ? localStorage.getItem('token') : '',
         );
         state.commit(
            'setUser',
            localStorage.getItem('user') ? localStorage.getItem('user') : '',
         );
         state.commit(
            'setIsLogged',
            !!(localStorage.getItem('user') && localStorage.getItem('token')),
         );
      },
      resetAuth(state) {
         localStorage.removeItem('token');
         localStorage.removeItem('user');
         state.commit('setToken', '');
         state.commit('setUser', '');
         state.commit('setIsLogged', false);
      },
   },
};

export default auth;

/*

state.user = localStorage.getItem("user") ? localStorage.getItem("user") :false
      if ((state.token) && (state.user)) {
        state.is_logged = true
      } */
