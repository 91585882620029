const forms = {
   state: () => ({
      systemEditItem: null,
      tagEditItem: null,
      clipboard: null,
   }),

   mutations: {
      updateSystemItem(state, item) {
         state.systemEditItem = item;
      },
      updateTagItem(state, item) {
         state.tagEditItem = item;
      },
      updateClipboard(state, item) {
         state.clipboard = item;
      },
   },

   actions: {
      setSystemItemForm(state, item) {
         state.commit('updateSystemItem', item);
      },
      setTagItemForm(state, item) {
         state.commit('updateTagItem', item);
      },
      resetAllForms(state) {
         state.commit('updateSystemItem', null);
         state.commit('updateTagItem', null);
      },
      copyTagToClipboard(state, item) {
         const rw_item = item;
         const propsToRemove = ['id', 'point_cloud_tag_id', 'site_id', 'system_tag_id', 'files'];
         propsToRemove.forEach((prop) => delete rw_item[prop]);
         state.commit('updateClipboard', rw_item);
      },
   },
   getter: {
      systemEditItem: (state) => state.forms.systemEditItem,
      tagEditItem: (state) => state.forms.tagEditItem,
      clipboard: (state) => state.forms.clipboard,
   },
};

export default forms;
