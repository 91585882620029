import store from '../store';
import { bus, currentVue } from '@/main';

export default class Util {
   static getBaseUrl() {
      return process.env.API;
   }

   static sendEvent(event, category, label, value) {
      currentVue.$mixpanel.track(event, { action: category, label: label, value: value });
      currentVue.$gtag.event(event, { event_category: category, event_label: label, value: value });
      console.log('event fired by utils', event, category, label, value);
   }

   /**
    * static showConfirm
    * @param {String} title
    * @param {String} message
    * @param {func} onConfirmCallBack
    * @param {func} onCancelCallBack
    * This function lunches a confirm dialog
    */
   static showConfirm(title, message, onConfirmCallBack, onCancelCallBack) {
      store.dispatch('app_setConfirmData', {
         title: title,
         message: message,
         onConfirm: onConfirmCallBack,
         onCancel: onCancelCallBack,
      });
      store.dispatch('app_setConfirmVisible', true);
   }

   static showMessage(msg, type = 'success') {
      store.dispatch('alertUser', { message: msg, type: type });
   }

   /**
   * @param {Boolean} isActive
   */
   static loading(isActive = true) {
      bus.$emit('loadingOverlay', isActive);
   }

   /**
    * @param {String} type
    * @returns {RegExp}
    */
   static rule(type) {
      switch (type) {
      case 'email':
         return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      case 'name':
         return /^[A-Za-z ]+$/;
      case 'phone':
         return /^[0-9+-]+$/;
      default:
         return /^[A-Za-z0-9 ]+$/;
      }
   }
}
