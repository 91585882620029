import PointcloudService from '@/service/PointcloudService.js';
import SystemService from '@/service/SystemService';
import TagService from '@/service/TagService';
import CategoryService from '@/service/CategoryService';

const lists = {
   state: () => ({
      pointClouds: [],
      systems: [],
      tags: [],
      categories: [],
      catsByParentWithChildren: [],
      tagsTree_active: {
         parentCat: null,
         childCat: null,
      },
      currentTagId: null,
      tagTree: [],
      allTags: [],
      tag_toForceViewable: null,
   }),

   mutations: {
      updateTagTree(state, tagTree) {
         state.tagTree = tagTree;
      },
      updatePointClouds(state, items) {
         state.pointClouds = items;
      },
      updateSystems(state, items) {
         state.systems = items;
      },
      updateTags(state, items) {
         state.tags = items;
      },
      updateCategories(state, items) {
         state.categories = items;
      },
      updateCatsByParentWithChildren(state, items) {
         state.catsByParentWithChildren = items;
      },
      setTagsTree_parentCat(state, parentCat) {
         state.tagsTree_active.parentCat = parentCat;
      },
      setTagsTree_childCat(state, childCat) {
         state.tagsTree_active.childCat = childCat;
      },
      setCurrentTagId(state, id) {
         state.currentTagId = id;
      },
      setAllTags(state, tags) {
         state.allTags = tags;
      },
      setTag_toForceViewable(state, tag) {
         state.tag_toForceViewable = tag;
      },
   },

   actions: {
      updateTagTree(state) {
         if (!state.getters.site) { state.commit('updateTagTree', []); return; }
         const tagService = new TagService();
         tagService.getTreeBySiteID(state.getters.site.id).then((res) => {
            state.commit('updateTagTree', res.data);
         });
         tagService.getBySiteID(state.getters.site.id).then((res) => {
            state.commit('setAllTags', res.data);
         });
      },
      updateTagsTreeParentCat(state, parentCat) {
         state.commit('setTagsTree_parentCat', parentCat);
      },
      updateTagsTreeChildCat(state, childCat) {
         state.commit('setTagsTree_childCat', childCat);
      },

      updateCategories(state) {
         const categoryService = new CategoryService();
         categoryService.getAll().then((response) => {
            state.commit('updateCategories', response.data);
         });
         categoryService.list().then((response) => {
            state.commit('updateCatsByParentWithChildren', response.data.data);
         });
      },

      updateCatsByParentWithChildren(state) {
         if (!state.getters.listCatsByParentWithChildren.length) {
            const categoryService = new CategoryService();
            categoryService.list().then((response) => {
               state.commit('updateCatsByParentWithChildren', response.data.data);
            });
         }
      },

      updatePointClouds(state) {
         if (!state.getters.site) { state.commit('updatePointClouds', null); console.log('error no site in module'); return; }
         const pointcloudService = new PointcloudService();
         pointcloudService.list(state.getters.site.id).then((list) => {
            console.log('lists.js src/store/modules/lists.js updatePointClouds list', list);
            if (list) {
               state.commit('updatePointClouds', list.data);
               if (!state.getters.currentPointCloud) {
                  console.log('currentPointCloud not set, setting to first in list');
                  state.commit('setCurrentPointCloud', list.data[0]);
               } else {
                  console.log('currentPointCloud already set');
               }
            }
         });
      },
      updateSystems(state) {
         if (!state.getters.site) { state.commit('updateSystems', null); return; }
         const systemServiceService = new SystemService();
         systemServiceService.list(state.getters.site.id).then((list) => {
            if (list) {
               state.commit('updateSystems', list.data);
            }
         });
      },
      updateTags(state) {
         if (!state.getters.currentSystem) { state.commit('updateTags', null); return; }
         const tagService = new TagService();
         tagService.getBySystemID(state.getters.currentSystem.id).then((list) => {
            if (list) {
               state.commit('updateTags', list.data);
            }
         });
         tagService.getBySiteID(state.getters.site.id).then((res) => {
            state.commit('setAllTags', res.data);
         });
      },
      forceViewableTag(state, tag) {
         state.commit('setTag_toForceViewable', tag);
      },
   },
   getter: {
      listPointClouds: (state) => state.lists.pointClouds,
      listSystems: (state) => state.lists.systems,
      listTags: (state) => state.lists.tags,
      listCategories: (state) => state.lists.categories,
      listCatsByParentWithChildren: (state) => state.lists.catsByParentWithChildren,
      getTagsTree_active: (state) => state.lists.tagsTree_active,
      getTagTree: (state) => state.lists.tagTree,
      getCurrentTagId: (state) => state.lists.currentTagId,
      getAllTags: (state) => state.lists.allTags,
      listForcedViewbleTag: (state) => state.lists.tag_toForceViewable,
   },
};

export default lists;
