import TagService from '@/service/TagService';

const tags = {
   state: {
      is_popupVisilbe: false,
      popupTagData: {},
      viewableTags: [],
   },
   mutations: {
      SET_POPUP_VISIBLE(state, visible) {
         state.is_popupVisilbe = visible;
      },
      SET_POPUP_TAG_DATA(state, data) {
         state.popupTagData = data;
      },
      SET_VIEWABLE_TAGS(state, vtags) {
         state.viewableTags = vtags;
      },
   },
   actions: {
      tags_setPopupVisible({ commit }, visible) {
         commit('SET_POPUP_VISIBLE', visible);
      },
      tags_setPopupTagData(state, data) {
         const tagObj = state.getters.getAllTags.find((o) => o.id === data.id);
         state.commit('SET_POPUP_TAG_DATA', tagObj);
      },
      tags_closePopup({ commit }) {
         commit('SET_POPUP_VISIBLE', false);
         commit('SET_POPUP_TAG_DATA', false);
      },
      tags_setPopupTagDataAndShow(state, data) {
         if (state.getters.tags_popupTagData.id !== data.id) {
            state.commit('SET_POPUP_VISIBLE', false);
            const tagObj = state.getters.getAllTags.find((o) => o.id === data.id);
            state.commit('SET_POPUP_TAG_DATA', tagObj);
            setTimeout(() => {
               state.commit('SET_POPUP_VISIBLE', true);
            }, 100);
         }
      },
      tags_deleteCurrentPopupTag(state) {
         const tagId = state.getters.tags_popupTagData.id;
         if (!tagId) { return; }
         const tagService = new TagService();
         tagService.delete(tagId).then((res) => {
            state.dispatch('tags_closePopup');
            state.dispatch('updateSystems');
            state.dispatch('updateTags');
            state.dispatch('updateTagTree');
         });
      },
      tags_updateViewableTags(state, data) {
         state.commit('SET_VIEWABLE_TAGS', data);
      },
   },
   getter: {
      tags_isPopupVisible: (state) => state.tags.is_popupVisilbe,
      tags_popupTagData: (state) => state.tags.popupTagData,
      tags_viewableTags: (state) => state.tags.viewableTags,
   },
};

export default tags;
