import Vue from 'vue';
import axios from 'axios';
import router from '@/router';
import error from '@/utils/error.js';

export default class ParentService {
   constructor(ref = null) {
      this._http = Vue.http;
      this._ref = ref;
   }

   // eslint-disable-next-line class-methods-use-this
   get baseUrl() {
      return `${process.env.VUE_APP_API}/api/`;
   }

   errorHandler(err) {
      if (err.status === 401) {
         router.push({ path: '/login' });
         return true;
      }
      /*
      if (this._ref) {
         this._ref.$message.error(error.toHtml(err.data.errors));
      }
      */
      return err;
   }

   customGet(url, params = [], contentType = 'application/json') {
      return this._http.get(url, { params: params }, { headers: { 'Content-Type': contentType } })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customGetDownload(url, params = [], contentType = 'application/json') {
      return this._http.get(url, { params: params, responseType: 'arraybuffer' }, { headers: { 'Content-Type': contentType }, responseType: 'arraybuffer' })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customPost(url, dto = [], contentType = 'application/json') {
      return this._http.post(url, dto, { headers: { 'Content-Type': contentType } })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customPut(url, dto = [], contentType = 'application/json') {
      return this._http.put(url, dto, { headers: { 'Content-Type': contentType } })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customPatch(url, dto = [], contentType = 'application/json') {
      return this._http.patch(url, dto, { headers: { 'Content-Type': contentType } })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customDelete(url, dto = [], contentType = 'application/json') {
      Vue.http.headers.common['Content-Type'] = contentType;
      return this._http.delete(url, dto, { headers: { 'Content-Type': contentType } })
         .then(
            (res) => {
               if (res.ok) {
                  return res;
               }
               return false;
            },
            (err) => this.errorHandler(err),
         );
   }

   customUpload(url, file) {
      const formData = new FormData();
      formData.append('file', file);
      const token = localStorage.getItem('token');
      return axios.post(url,
         formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
               Authorization: `Bearer ${token}`,
            },
         }).then(
         (res) => res,
         (err) => this.errorHandler(err),
      );
   }
}
