import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
   theme: {
      dark: false,
      options: { customProperties: true },
      themes: {
         light: {
            primary: '#00B1FF',
            accent: '#00FFF6',
            secondary: '#2488FF',
            success: '#00D5A8',
            info: '#2488FF',
            warning: '#FF3E00',
            error: '#FF0035',
            bg: '#012a52',
            bgdark: '#002244',
            bgdarker: '#001122',
            bgoverlay: '#00519A',
            trhover: '#E4F6FF',

         },
         dark: {
            primary: '#00B1FF',
            accent: '#00FFF6',
            secondary: '#2488FF',
            success: '#00FFC9',
            info: '#2488FF',
            warning: '#FF3E00',
            error: '#FF0035',

         },
      },
   },
});
