import ParentService from '@/service/ParentService.js';

export default class PointcloudService extends ParentService {
   get(id) {
      const url = `${super.baseUrl}point_cloud/${id}`;
      return super.customGet(url);
   }

   list(siteId) {
      const url = `${super.baseUrl}point_cloud/site/${siteId}`;
      return super.customGet(url);
   }

   store() {
      const url = `${super.baseUrl}point_cloud`;
      return super.customPost(url);
   }

   update(id, dto) {
      const url = `${super.baseUrl}point_cloud/${id}`;
      return super.customPut(url, dto);
   }
}
