/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
// import VueCookies from "vue-cookies";

import util from '@/utils/';
// import decode from "jwt-decode";
// TODO: add jwt validation against UID

import store from './store';

export default class Auth {
   constructor(router) {
      this._initialized = false;
      this._authenticated = false;
      this._user = null;
      this._router = router;
      this._store = store;
      this._token = null;
      // this.check(); disabled on 17/01/22 login error messages
      this.checkOnly();
   }

   get store() {
      return this._store;
   }

   get router() {
      return this._router;
   }

   get initialized() {
      return this._initialized;
   }

   get authenticated() {
      if (!this._initialized) {
         this.check();
      }
      return this._authenticated;
   }

   get role() {
      return this.user.role;
   }

   get user() {
      return this._user;
   }

   openLogin() {
      this._router.push({
         path: 'login',
      });
   }

   checkOnly() {
      if (!this._initialized) {
         this._initialized = true;
         this.createAddAuthorizationInterceptor();
         this.createRefreshTokenInterceptor();
      }
      this._token = localStorage.getItem('token');
      if (!(typeof this._token !== 'undefined' && this._token !== null)) {
         this._authenticated = false;
         return;
      }
      this._user = JSON.parse(localStorage.getItem('user'));
      if (!(typeof this._user !== 'undefined' && this._user !== null)) {
         this._authenticated = false;
         return;
      }
      this._authenticated = true;
      this._store.dispatch('updateAuth');
   }

   check() {
      if (!this._initialized) {
         this._initialized = true;
         this.createAddAuthorizationInterceptor();
         this.createRefreshTokenInterceptor();
      }
      this._token = localStorage.getItem('token');
      if (!(typeof this._token !== 'undefined' && this._token !== null)) {
         this.signout('', 'error');
         this._authenticated = false;
         return;
      }
      this._user = JSON.parse(localStorage.getItem('user'));
      if (!(typeof this._user !== 'undefined' && this._user !== null)) {
         this.signout('', 'error');
         this._authenticated = false;
         return;
      }
      this._authenticated = true;
      this._store.dispatch('updateAuth');
   }

   createAddAuthorizationInterceptor() {
      Vue.http.interceptors.push((request, next) => {
         if (this._authenticated) {
            const token = this._token;
            if (token) {
               request.headers.set('Authorization', `Bearer ${token}`);
            }
         }
         next();
      });
   }

   createRefreshTokenInterceptor() {
      Vue.http.interceptors.push((request, next) => {
         next((response) => {
            if (response.status === 406) {
               this.signout('Cannot refresh, please relogin', 'error');
            }
            if (response.status === 401) {
               this._authenticated = false;
               if (this._token) {
                  return this.callRefreshToken(this._token).then((response) => {
                     if (response) {
                        if (response.status === 401) {
                           this.signout('Cannot refresh, please relogin', 'error');
                        }
                        const token = response.data.access_token;
                        localStorage.setItem('token', token);
                        this._token = token;
                        this._authenticated = true;
                        this._store.dispatch('updateAuth');
                        return Vue.http(request).then((data) => data);
                     }
                     this.signout('Token expired!!', 'error');
                     return false;
                  });
               }
               this.signout('Token expired!!', 'error');
               return false;
            }
         });
      });
   }

   callRefreshToken(token) {
      return Vue.http.post(
         `${process.env.VUE_APP_API}/api/refresh`,
         {},
         {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               Authorization: `Bearer ${token}`,
            },
         },
      );
   }

   recover(email) {
      const data = `email=${email.toLowerCase()}`;
      Vue.http
         .post(`${process.env.VUE_APP_API}/api/password/email`, data, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
         })
         .then((response) => {
            if (response.ok && response.status === 200) {
               util.showMessage('Recovery link sent to your email', 'success');
               this.openLogin();
            } else {
               util.showMessage('Error on send recovery link', 'error');
            }
         })
         .catch(() => {
            util.showMessage('Error on send recovery link', 'error');
         });
   }

   reset(resetdata) {
      const { token } = resetdata;
      const { email } = resetdata;
      const pass = resetdata.password;
      const pass2 = resetdata.password2;

      let data = `token=${token}`;
      data += `&email=${email.toLowerCase()}`;
      data += `&password=${pass}`;
      data += `&password_confirmation=${pass2}`;
      Vue.http
         .post(`${process.env.VUE_APP_API}api/password/reset`, data, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
         })
         .then((response) => {
            if (response.ok && response.status === 200) {
               util.showMessage('Password reset successfully', 'success');
               this.openLogin();
            } else {
               util.showMessage('Error on send recovery link', 'error');
            }
         })
         .catch(() => {
            util.showMessage('Error on send recovery link', 'error');
         });
   }

   signin(username, password) {
      this._store.dispatch('resetAuth');
      let index = true;
      while (index !== -1) {
         index = Vue.http.interceptors.findIndex((interceptor) => {
            return typeof interceptor === 'function';
         });
         console.log('inter', index);
         if (index !== -1) {
            Vue.http.interceptors.splice(index, 1);
         }
      }
      const login = username.toLowerCase();
      const data = `email=${login}&password=${password}`;
      Vue.http
         .post(`${process.env.VUE_APP_API}/api/login`, data, {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
            },
         })
         .then(
            (response) => {
               if (response.ok && response.status === 200) {
                  this.authInfo(response.data);
               } else {
                  // util.showMessage('Error on login', 'error')
                  this.signout('Error on login');
               }
            },
            (errors) => {
               // util.showMessage('Error on login', 'error')
               this.signout('Error on login');
            },
         );
   }

   authInfo(data) {
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('user', JSON.stringify(data.user));
      this._store.dispatch('updateAuth');
      this.createAddAuthorizationInterceptor();
      this.createRefreshTokenInterceptor();
      this.check();
   }

   signout(msg = '', type = '') {
      this.limparAuth();
      if (msg != '') {
      // util.showMessage(msg, type);

         this._store.dispatch('alertUser', { message: msg, type: 'warning' });
      }
      this.openLogin();
   }

   limparAuth() {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      this._store.dispatch('updateAuth');
      window.sessionStorage.clear();
      this._authenticated = false;
      this._user = null;
   }
}
