import Vue from 'vue';
import Router from 'vue-router';
import { auth } from '@/main';
import Layout from '@/views/layout/Layout';


Vue.use(Router);

const beforeEachFunc = function (to, from, next) {
   if (!auth.store.getters.isLogged) {
      // auth.router.push({ path: 'login' })
      next({ name: 'login' });
   } else {
      if ((auth.role === 'admin') && (to.name === 'home')) {
         next({ name: 'companies' });
      }
      next();
   }
};

export default new Router({
   scrollBehavior: () => ({ y: 0 }),
   routes: [
      {
         path: '/login',
         name: 'login',
         component: () => import('@/views/auth/index'),
      },
      {
         path: '/register',
         name: 'register',
         component: () => import('@/views/auth/register'),
      },
      {
         path: '/recover',
         name: 'recover',
         component: () => import('@/views/auth/recover'),
      },
      {
         path: '/reset',
         // path: '/reset/:token',
         props: true,
         name: 'reset',
         component: () => import('@/views/auth/reset'),
      },
      {
         path: '',
         name: 'mainhome',
         component: Layout,
         redirect: 'home',
         beforeEnter: beforeEachFunc,
         children: [
            {
               path: '/home',
               name: 'home',
               component: () => import('@/views/home/index'),
               meta: { title: 'Home', icon: 'mdi-map-marker', show: true, roles: ['owner', 'writer', 'viewer'], isSideBar: false },
            },
            {
               path: '/main',
               name: 'main',
               component: () => import('@/views/main/index'),
               meta: { title: 'Home', icon: 'mdi-map-marker', show: true, roles: ['owner', 'writer', 'viewer'], isSideBar: true },
            },
            {
               path: '/dashboard',
               name: 'dashboard',
               component: () => import('@/views/dashboard/index'),
               meta: { title: 'Dashboard', icon: 'mdi-view-dashboard', show: true, roles: ['admin', 'owner', 'writer', 'viewer'], isSideBar: true },

            },
            {
               path: '/companies',
               name: 'companies',
               component: () => import('@/views/companies/index'),
               meta: { title: 'Companies', icon: 'mdi-domain', show: true, roles: ['admin'], isSideBar: false },
            },
            {
               path: '/users',
               name: 'users',
               component: () => import('@/views/users/index'),
               meta: { title: 'Users', icon: 'mdi-domain', show: true, roles: ['owner'], isSideBar: false },
            },

            {
               path: '/categories',
               name: 'categories',
               component: () => import('@/views/categories/index'),
               meta: { title: 'Categories', icon: 'mdi-file-tree', show: true, roles: ['admin', 'owner'], isSideBar: false },
            },
            {
               path: '/systems',
               name: 'systems',
               component: () => import('@/views/systems/index'),
               meta: { title: 'Systems', icon: 'mdi-pipe-disconnected', show: true, roles: ['owner', 'writer', 'viewer'], isSideBar: true },
            },
            {
               path: '/pointcloud',
               name: 'pointcloud',
               component: () => import('@/views/pointcloud/index'),
               meta: { title: 'Models', icon: 'mdi-cube-scan', show: true, roles: ['owner', 'writer', 'viewer'], isSideBar: true },
            },
         ],
      },
   ],
});
