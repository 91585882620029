<template>
   <v-app>
      <sidebar class="sidebar-container" />
      <topbar />
      <v-main style="z-index:1;">
         <!-- Provides the application the proper gutter -->
         <v-container fluid class="pa-0">
            <!-- If using vue-router -->
               <router-view></router-view>
         </v-container>
         
      </v-main>

      <v-snackbar
         v-model="snackbar"
         :color="messageType"
         elevation="10"
         transition="slide-y-transition"
         timeout="3000"
         @input="resetSnackbar"
      >
         {{ message }}
      </v-snackbar>

      <v-dialog
         v-model="showConfirmDialog"
         persistent
         transition="dialog-fade-transition"
         max-width="600"
      >

         <v-card color="white">
            <v-toolbar
               color="error"
               dark
            >
               {{ confirmData.title }} </v-toolbar>
            <v-card-text>
               <div class="d-flex">
                  <div class="d-flex align-center ml-5">
                     <v-icon color="error" size="45" class="mr-0">mdi-alert-outline</v-icon>
                  </div>
                  <div class="text-h6 pa-12" v-html="confirmData.message"></div>
               </div>
            </v-card-text>
            <v-card-actions class="justify-end">
               <v-btn
                  color="error darken-1"
                  text
                  large
                  @click="confirmDialogCancel"
                  class="mr-5"
               >
                  Cancel
               </v-btn>
               <v-btn
                  color="error darken-1"
                  text
                  large
                  @click="confirmDialogConfirm"
               >
                  OK
               </v-btn>
            </v-card-actions>
         </v-card>
      </v-dialog>
   </v-app>
</template>

<script>
/* eslint-disable vue/no-side-effects-in-computed-properties */
import Sidebar from './components/Sidebar';
import topbar from './components/Topbar';
import ResizeMixin from './mixin/ResizeHandler';

export default {
   name: 'Layout',
   components: {
      Sidebar,
      topbar,
   },
   mixins: [ResizeMixin],
   computed: {
      confirmVisible() {
         return this.$store.getters.app_confirmVisible;
      },
      confirmData() {
         return this.$store.getters.app_confirmData;
      },
      message() {
         const msg = this.$store.getters.message;
         this.messageType = this.$store.getters.messageType;
         if (msg) {
            this.snackbar = true;
         }
         return this.$store.getters.message;
      },
      device() {
         return this.$store.getters.device;
      },
      classObj() {
         return {
            hideSidebar: !this.sidebar.opened,
            openSidebar: this.sidebar.opened,
            withoutAnimation: this.sidebar.withoutAnimation,
            mobile: this.device === 'mobile',
         };
      },
   },
   watch: {
      confirmVisible: {
         handler(val) {
            this.showConfirmDialog = false;
            if (val) {
               this.showConfirmDialog = true;
            }
         },
         immediate: true,
      },
   },
   data() {
      return {
         snackbar: false,
         messageType: 'success',
         dialog: true,
         showConfirmDialog: false,
      };
   },
   methods: {
      confirmDialogConfirm() {
         this.confirmData.onConfirm();
         this.$store.dispatch('app_resetConfirmDialog');
      },
      confirmDialogCancel() {
         this.confirmData.onCancel();
         this.$store.dispatch('app_resetConfirmDialog');
      },
      resetSnackbar() {
         this.$store.dispatch('alertUser', { message: '', type: 'success' });
      },
   },
};
</script>
<style lang="scss" scoped>
.roundTopLeft
{
   padding: 0;
   margin: 68px 0 0 77px;
   border-top-left-radius: 10px;
   /* shadow*/
   -webkit-box-shadow: -10px -10px;
}


</style>