const pointcloudView = {
   state: {
      iframeUrl: '',
      currentPointCloud: null,
      currentPointcloudTag: {},
      currentPointcloudAction: false,
      currentPointcloudTagList: [],
      pointCloudViewQueue: [],
   },
   mutations: {
      setIframeUrl: (state, url) => {
         state.iframeUrl = url;
      },
      setCurrentPointCloud: (state, pointCloudObj) => {
         state.currentPointCloud = pointCloudObj;
      },
      SET_CURRENT_POINTCLOUD_TAG(state, tag) {
         state.currentPointcloudTag = tag;
      },
      SET_CURRENT_POINTCLOUD_TAG_ACTION(state, action) {
         state.currentPointcloudAction = action;
      },
      SET_CURRENT_POINTCLOUD_TAG_LIST(state, tagList) {
         state.currentPointcloudTagList = tagList;
      },
      ADD_TO_QUEUE: (state, commitObj) => {
         state.pointCloudViewQueue.push(commitObj);
      },
      pointcloudView_ResetQueue: (state) => {
         state.pointCloudViewQueue = [];
      },
   },
   actions: {
      storeIframeUrl(state, url) {
         state.commit('setIframeUrl', url);
      },
      setCurrentPointCloud(state, pointCloudObj) {
         // state.commit('setCurrentPointCloud', null);
         state.commit('setCurrentPointCloud', pointCloudObj);
      },
      pointcloudView_setCurrentPointcloudTag(state, data) {
         if ((state.getters.pointcloudView_currentPointcloudTag === data.tag) && (state.getters.pointcloudView_currentPointcloudAction === data.action)) {
            state.commit('SET_CURRENT_POINTCLOUD_TAG', {});
            state.commit('SET_CURRENT_POINTCLOUD_TAG_ACTION', false);
         } else {
            if ((data.action === 'view') && (state.getters.currentPointCloud.id !== data.tag.point_cloud_tag.point_cloud_id)) {
               state.dispatch('setCurrentPointCloud', data.tag.point_cloud_tag.point_cloud);
               state.dispatch('pointcloudView_AddToQueue', { mutator: 'SET_CURRENT_POINTCLOUD_TAG', payload: data.tag });
               state.dispatch('pointcloudView_AddToQueue', { mutator: 'SET_CURRENT_POINTCLOUD_TAG_ACTION', payload: data.action });
               return;
            }
            state.commit('SET_CURRENT_POINTCLOUD_TAG', data.tag);
            state.commit('SET_CURRENT_POINTCLOUD_TAG_ACTION', data.action);
         }
      },

      /**
       * pointcloudView_AddToQueue
       * @param state
       * @param {mutator, payload} commitObj
       */
      pointcloudView_AddToQueue(state, commitObj) {
         state.commit('ADD_TO_QUEUE', commitObj);
      },

      /**
       * pointcloudView_ProcessQueue
       * @param state
       */
      pointcloudView_ProcessQueue(state) {
         state.getters.pointcloudView_queue.forEach((commitObj) => {
            state.commit(commitObj.mutator, commitObj.payload);
         });
         state.commit('pointcloudView_ResetQueue');
      },

      pointcloudView_setCurrentPointcloudTagList({ commit }, tagList) {
         commit('SET_CURRENT_POINTCLOUD_TAG_LIST', tagList);
      },
   },
   getter: {
      iframeUrl: (state) => state.pointcloudView.iframeUrl,
      currentPointCloud: (state) => state.pointcloudView.currentPointCloud,
      pointcloudView_currentPointcloudTag: (state) => state.pointcloudView.currentPointcloudTag,
      pointcloudView_currentPointcloudAction: (state) => state.pointcloudView.currentPointcloudAction,
      pointcloudView_currentPointcloudTagList: (state) => state.pointcloudView.currentPointcloudTagList,
      pointcloudView_queue: (state) => state.pointcloudView.pointCloudViewQueue,
   },
};

export default pointcloudView;
