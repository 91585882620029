var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"appHeader",attrs:{"color":"bg accent-4"}},[_c('v-card',{staticClass:"d-flex justify-space-between"},[_c('v-card',{staticClass:"mx-4 mt-6"},[(_vm.showHome && (_vm.auth.role!='admin'))?_c('v-icon',{staticClass:"mr-5",attrs:{"medium":"","color":"white"},on:{"click":function($event){return _vm.$router.push('home');}}},[_vm._v("home")]):_vm._e(),(_vm.currentSite)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"#fff"}},[_vm._v(_vm._s(_vm.currentSite.title))]):_vm._e()],1),_c('v-card',[_c('v-card',{staticClass:"d-flex justify-end align-top"},[((_vm.auth.role=='admin')||(_vm.auth.role=='owner')||(_vm.auth.role=='writer'))?_c('div',{staticClass:"d-flex justify-end align-top"},[_c('div',{staticClass:"accent--text pb-3 pt-6 pr-5 pl-5 custumupper"}),_c('v-card',{staticClass:"ownerButtons",attrs:{"color":"accent"}},[_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.auth.role=='owner'))?_c('v-icon',_vm._g(_vm._b({staticClass:"no-background-hover icon-color-hover pb-3 pt-4 pr-5 pl-5",attrs:{"color":"bg","size":"20"},on:{"click":function($event){return _vm.$router.push('users')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-account-multiple-outline ")]):_vm._e()]}}],null,false,2054904393)},[_c('span',[_vm._v("Users")])]),_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.auth.role=='admin'))?_c('v-icon',_vm._g(_vm._b({staticClass:"no-background-hover icon-color-hover pb-3 pt-4 pr-5 pl-5",attrs:{"color":"bg","size":"20"},on:{"click":function($event){return _vm.$router.push('companies')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-domain ")]):_vm._e()]}}],null,false,2047500981)},[_c('span',[_vm._v("Companies")])]),_c('v-tooltip',{attrs:{"open-on-hover":"","open-delay":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((_vm.auth.role=='owner') || (_vm.auth.role=='admin') || (_vm.auth.role=='writer'))?_c('v-icon',_vm._g(_vm._b({staticClass:"no-background-hover icon-color-hover pb-3 pt-4 pr-4 pl-5",attrs:{"color":"bg","size":"18"},on:{"click":function($event){return _vm.$router.push('categories')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-tree-outline ")]):_vm._e()]}}],null,false,2992039014)},[_c('span',[_vm._v("Categories")])])],1)],1):_vm._e(),_c('v-card',{staticClass:"mx-4 mt-2"},[_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":"","z-index":"10","transition":"scroll-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"info","size":"52"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])])],1)]}}])},[_c('v-card',{attrs:{"color":"white"}},[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('v-avatar',{attrs:{"color":"info"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.initials))])]),_c('v-divider',{staticClass:"my-2 mx-auto",attrs:{"inset":""}}),_c('h3',[_vm._v(_vm._s(_vm.auth.user.first_name+" "+_vm.auth.user.last_name))]),_c('div',{staticClass:"text-caption mt-1"},[_vm._v(" "+_vm._s(_vm.auth.user.email)+" "),_c('div',{staticClass:"text-caption text-uppercase secondary--text"},[_vm._v(_vm._s(_vm.auth.user.role))]),(_vm.auth.user.company)?_c('v-card',[_c('br'),_c('v-img',{staticClass:"mt-2 pa-1 mx-auto",attrs:{"height":"45","max-width":"80","contain":"","src":_vm.auth.user.company.logo}}),_c('br'),_vm._v(" "+_vm._s(_vm.auth.user.company.name)+" ")],1):_vm._e(),((_vm.auth.role=='admin'))?_c('v-card',[_c('v-icon',{staticClass:"mt-2 mx-auto rotate-15",attrs:{"large":"","color":"info"}},[_vm._v("mdi-crown-outline")]),_c('br'),_c('v-img',{staticClass:"mx-auto",attrs:{"src":require('@/assets/logo_light.svg'),"max-width":"80","contain":""}})],1):_vm._e()],1),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","color":"secondary","text":"","disabled":""}},[_vm._v(" Edit Account ")]),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","color":"secondary","text":""},on:{"click":_vm.signout}},[_vm._v(" Sign Out ")])],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }