<template>
   <v-card class="appHeader" color="bg accent-4">
      <v-card class="d-flex justify-space-between">
         <v-card class="mx-4 mt-6">
            <v-icon v-if="showHome && (auth.role!='admin')" medium color="white" class="mr-5" @click="$router.push('home');">home</v-icon>
            <span class="font-weight-bold" style="color:#fff;" v-if="currentSite">{{ currentSite.title }}</span>
         </v-card>
         <v-card>
            <v-card class="d-flex justify-end align-top">
               <div class="d-flex justify-end align-top" v-if="(auth.role=='admin')||(auth.role=='owner')||(auth.role=='writer')"> 
                  <div class="accent--text pb-3 pt-6 pr-5 pl-5 custumupper"></div>
                  <v-card color="accent" class="ownerButtons">
                     <v-tooltip open-on-hover open-delay="500" bottom>
                        <template v-slot:activator="{ on, attrs }">
                           <v-icon color="bg" size="20" class="no-background-hover icon-color-hover pb-3 pt-4 pr-5 pl-5"v-bind="attrs"
                        v-on="on" v-if="(auth.role=='owner')" @click="$router.push('users')">
                              mdi-account-multiple-outline
                           </v-icon>
                        </template>
                        <span>Users</span>
                     </v-tooltip>
                     <v-tooltip open-on-hover open-delay="500" bottom >
                        <template v-slot:activator="{ on, attrs }">                                 
                           <v-icon color="bg" size="20" class="no-background-hover icon-color-hover pb-3 pt-4 pr-5 pl-5" v-bind="attrs"
                        v-on="on" v-if="(auth.role=='admin')" @click="$router.push('companies')">
                              mdi-domain
                           </v-icon>
                        </template>
                        <span>Companies</span>
                     </v-tooltip>
                     <v-tooltip open-on-hover open-delay="500" bottom >
                        <template v-slot:activator="{ on, attrs }">
                           <v-icon color="bg" size="18" class="no-background-hover icon-color-hover pb-3 pt-4 pr-4 pl-5" v-bind="attrs"
                        v-on="on" v-if="(auth.role=='owner') || (auth.role=='admin') || (auth.role=='writer')" @click="$router.push('categories')">
                              mdi-file-tree-outline
                           </v-icon>
                        </template>
                        <span>Categories</span>
                     </v-tooltip>
                  </v-card>
               </div>
               <!-- <v-card class="mx-4 mt-2">
                  <v-menu bottom min-width="200px" rounded offset-y z-index="10" transition="scroll-y-transition">
                     <template v-slot:activator="{ on }">
                        <v-btn
                           icon
                           x-large
                           v-on="on"
                        >
                           <v-icon>
                              mdi-chat
                           </v-icon>
                        </v-btn>
                     </template>
                     <v-card color="white">
                        <v-list-item-content class="justify-center">
                           <div class="mx-auto text-center">
                              
                              
                           </div>
                        </v-list-item-content>
                     </v-card>
                  </v-menu>
               </v-card> -->
               <v-card class="mx-4 mt-2">
                  <v-menu bottom min-width="200px" rounded offset-y z-index="10" transition="scroll-y-transition">
                     <template v-slot:activator="{ on }">
                        <v-btn
                           icon
                           x-large
                           v-on="on"
                        >
                           <v-avatar
                              color="info"
                              size="52"
                           >
                              <span class="white--text text-h5">{{ initials }}</span>
                           </v-avatar>
                        </v-btn>
                     </template>
                     <v-card color="white">
                        <v-list-item-content class="justify-center">
                           <div class="mx-auto text-center">
                              <v-avatar
                                 color="info"
                              >
                                 <span class="white--text text-h5">{{ initials }}</span>
                              </v-avatar>
                              <v-divider
                                 inset
                                 class="my-2 mx-auto"
                              >
                              </v-divider>
                              <h3>{{ auth.user.first_name+" "+auth.user.last_name }}</h3>
                              <div class="text-caption mt-1">
                                 {{ auth.user.email }}
                                 <div class="text-caption text-uppercase secondary--text">{{ auth.user.role }}</div>
                                 <v-card v-if="auth.user.company" >
                                    <br>
                                    <v-img height="45" max-width="80" contain class="mt-2 pa-1 mx-auto"  :src="auth.user.company.logo"/>
                                    <br>
                                    {{ auth.user.company.name}}
                                 </v-card>
                                 <v-card v-if="(auth.role=='admin')" >
                                    <v-icon large color="info" class="mt-2 mx-auto rotate-15">mdi-crown-outline</v-icon>
                                    <br>
                                    <v-img :src="require('@/assets/logo_light.svg')" class="mx-auto" max-width="80" contain />
                                 </v-card>
                              </div>
                              <v-divider class="my-3"></v-divider>
                              <v-btn
                                 depressed
                                 color="secondary"
                                 text
                                 disabled
                              >
                                 Edit Account
                              </v-btn>
                              <v-divider class="my-3"></v-divider>
                              <v-btn
                                 depressed
                                 color="secondary"
                                 text
                                 @click="signout"
                              >
                                 Sign Out
                              </v-btn>
                           </div>
                        </v-list-item-content>
                     </v-card>
                  </v-menu>
               </v-card>
            </v-card>
         </v-card>
      </v-card>
   </v-card>
</template>

<script>
import { auth } from '@/main';
import Util from '@/utils';
//import Chat from "@/components/chat/Chat.vue";
//import "@twilio/conversations"


export default {
   //components: { Chat },
   data() {
      return {
         auth: auth,
         initials: null
      };
   },
   computed: {
      showHome() {
         return (this.$route.name !== 'home');
      },
      currentSite() {
         if (this.$store.getters.site) { return this.$store.getters.site; }
         return false;
      },
   },
   mounted() {
      this.initials = auth.user.first_name.charAt(0) + auth.user.last_name.charAt(0);
      console.log(auth.user);
   },
   methods: {
      signout() {
         //this.$mixpanel.track('logout');
         Util.sendEvent('logout');
         auth.signout('Good bye!', 'primary');
      },
   },
};
</script>

<style scoped>
.no-background-hover:focus::after {
   display: none !important;
}
.icon-color-hover:hover {
   color: var(--v-bgoverlay-base) !important;
}
.appHeader {
   position: fixed !important;
   top:0 !important;
   left:0 !important;
   width:100% !important;
   padding-left:80px !important;
   z-index: 1;
   height: 68px !important;
}
.ownerButtons {
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;

  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

}
.custumupper {
   text-transform: uppercase;
   font-weight: 500;
   font-size: 0.6rem !important;
}

</style>
<style lang="sass" scoped>
@import '@/sass/variables.scss'
.v-tooltip__content 
   border: 1px solid rgba(255,255,255,0.2) !important
   background-color: #002244
</style>
