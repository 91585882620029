import ParentService from '@/service/ParentService.js';

export default class TagService extends ParentService {
   getTreeBySiteID(site_id) {
      const url = `${super.baseUrl}tag/treebysite/${site_id}`;
      return super.customGet(url);
   }

   getBySystemID(system_id) {
      const url = `${super.baseUrl}system_tags/system/${system_id}`;
      return super.customGet(url);
   }

   getBySiteID(site_id) {
      const url = `${super.baseUrl}tag/bysite/${site_id}`;
      return super.customGet(url);
   }

   show(id) {
      const url = `${super.baseUrl}tag/${id}`;
      return super.customGet(url);
   }

   add(dto, system_id) {
      const data = dto;
      data.system_id = system_id;
      const url = `${super.baseUrl}tag`;
      return super.customPost(url, dto);
   }

   update(id, dto) {
      const url = `${super.baseUrl}tag/${id}`;
      return super.customPut(url, dto);
   }

   delete(id) {
      const url = `${super.baseUrl}tag/${id}`;
      return super.customDelete(url);
   }

   getAll(id, dto) {
      const url = `${super.baseUrl}system_tags/system/${id}`;
      return super.customGet(url, dto);
   }

   getAllUnlinked(id, dto) {
      const url = `${super.baseUrl}system_tags/system/unlinked/${id}`;
      return super.customGet(url, dto);
   }

   getCategoriesed(id) {
      const url = `${super.baseUrl}system_tags/categoriesed/system/${id}`;
      return super.customGet(url);
   }

   pointCloudTagId(id) {
      const url = `${super.baseUrl}system_tags/point_cloud_tag_id/${id}`;
      return super.customGet(url);
   }

   upload(dto) {
      const url = `${super.baseUrl}system_tags/upload`;
      return super.customUpload(url, dto);
   }
}
