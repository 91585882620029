const systemView = {
   state: {
      currentSystemTag: {},
      currentSystemTagAction: false,
      pz_moveToTarget: {
         x: 0,
         y: 0,
         w: 0,
         h: 0,
      },
      pz_showHideTarget: {
         show: true,
      },
      currCompSize: 'large',
   },
   mutations: {
      SET_CURRENT_SYSTEM_TAG(state, tag) {
         state.currentSystemTag = tag;
      },
      SET_CURRENT_SYSTEM_TAG_ACTION(state, action) {
         state.currentSystemTagAction = action;
      },
      SET_PZ_MOVE_TO_TARGET(state, target) {
         state.pz_moveToTarget = target;
      },
      SET_PZ_SHOW_HIDE_TARGET(state, target) {
         state.pz_showHideTarget = target;
      },
      SET_CURR_COMP_SIZE(state, size) {
         state.currCompSize = size;
      }, 
   },
   actions: {
      systemView_setCurrentSystemTag({ commit }, tag) {
         commit('SET_CURRENT_SYSTEM_TAG', tag);
      },
      systemView_setCurrentSystemTagAction(state, action) {
         state.commit('SET_CURRENT_SYSTEM_TAG_ACTION', action);
      },
      systemView_setPZMoveToTarget({ commit }, target) {
         commit('SET_PZ_MOVE_TO_TARGET', target);
      },
      systemView_setPZShowHideTarget({ commit }, target) {
         commit('SET_PZ_SHOW_HIDE_TARGET', target);
      },
      systemView_setCurrCompSize({ commit }, size) {
         commit('SET_CURR_COMP_SIZE', size);
      },
   },
   getter: {
      systemView_currentSystemTag: (state) => state.systemView.currentSystemTag,
      systemView_currentSystemTagAction: (state) => state.systemView.currentSystemTagAction,
      systemView_pz_moveToTarget: (state) => state.systemView.pz_moveToTarget,
      systemView_pz_showHideTarget: (state) => state.systemView.pz_showHideTarget,
      systemView_currectCompSize: (state) => state.systemView.currCompSize,
   },
};

export default systemView;
