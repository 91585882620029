<template>
   <v-navigation-drawer color="bg" v-model="drawer"
                        mini-variant-width="77"
                        floating
                        :mini-variant="true" height="100%" permanent app  >
      <v-toolbar elevation="0" class="flex" color="bg" height="68">
         <v-img
            v-bind:src="require('@/assets/' + img_showing)"
            :max-height="50"
            :max-width= "76"
            :width="50"
            contain
            position="center center"
            class="mblogo2"></v-img>
      </v-toolbar>

      <v-list dense flat v-if="showNavbar">
         <v-list-item-group v-model="navList" @change="showListComp">
            <v-list-item value="PointCloudList">
               <v-list-item-icon class="my-5 mx-0 pa-0">
                  <v-tooltip open-on-hover open-delay="500" right>
                     <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on">mdi-cube-scan</v-icon>
                     </template>
                     <span >Models</span>
                  </v-tooltip>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title>Show models</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-list-item value="SystemList">
               <v-list-item-icon class="my-5 mx-0 pa-0">
                  <v-tooltip open-on-hover open-delay="500" right >
                     <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on">mdi-sitemap</v-icon>
                     </template>
                     <span>Systems</span>
                  </v-tooltip>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title>Show systems</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
            <v-list-item value="TagList" v-if="showTagsIcon">
               <v-list-item-icon class="my-5 mx-0 pa-0">
                  <v-tooltip open-on-hover open-delay="500" right >
                     <template v-slot:activator="{ on, attrs }">
                     <v-icon
                        dark
                        v-bind="attrs"
                        v-on="on">mdi-tag-multiple</v-icon>
                     </template>
                     <span>Tags</span>
                  </v-tooltip>
               </v-list-item-icon>
               <v-list-item-content>
                  <v-list-item-title>Show Tags</v-list-item-title>
               </v-list-item-content>
            </v-list-item>
         </v-list-item-group>
      </v-list>
   </v-navigation-drawer>
</template>

<script>
import { auth } from '@/main';

export default {
   data() {
      return {
         auth: auth,
         drawer: true,
         items: [],
         mini: true,
         img_logo: 'logo.png',
         img_logo_alter: 'logo.png',
         img_showing: 'logo.png',
         showNavbar: true,
         navList: null,
         showTagsIcon: false,
      };
   },
   computed: {
      currentSystem() {
         return this.$store.getters.currentSystem;
      },
      currentPointCloud() {
         return this.$store.getters.currentPointCloud;
      },
      navListState() {
         return this.$store.getters.activeListComp;
      },
   },
   watch: {
      currentPointCloud(val) {
         if (val != null) {
            this.showTagsIcon = true;
         } else {
            this.showTagsIcon = false;
         }
      },
      navListState(val) {
         this.navList = val;
      },
      $route(val) {
         this.showNavbar = val.meta.isSideBar;
      },
      mini(val) {
         if (val === true) {
            this.img_showing = this.img_logo_alter;
         } else {
            this.img_showing = this.img_logo;
         }
      },
   },
   created() {
      this.showNavbar = this.$route.meta.isSideBar;
      this.navList = this.navListState;
      this.showTagsIcon = (this.currentPointCloud != null);
   },
   methods: {
      showListComp() {
         this.$store.dispatch('setActiveListComp', this.navList);
      },
      canSee(roles) {
         if (roles.includes(this.auth.user.role)) {
            return true;
         }
         return false;
      },
   },
};
</script>
<style lang="sass" scoped>
@import '@/sass/variables.scss'
.theme--light.v-list-item
   .v-icon
      color: map-get($colors-alpha, 'white-5') !important
.theme--light.v-list-item:hover
   .v-icon
      color: #fff !important
      padding:0 !important

.theme--light.v-list-item--active
   border-left: 5px var(--v-secondary-base) solid
   border-radius:0
   .v-icon
      color: white !important
.mblogo2
   height: 50px
   transition: all 200ms

.v-tooltip__content 
   border: 1px solid rgba(255,255,255,0.2) !important
   background-color: #002244

</style>
