<template>
   <div @contextmenu="preventDefault">
      <router-view />
      <v-overlay :value="loading" absolute><v-progress-circular indeterminate size="70" width="8" color="btns"></v-progress-circular></v-overlay>

   </div>
</template>

<script>
import { bus } from '@/main';

export default {
   data() {
      return {
         loading: false,
      };
   },
   created() {
      bus.$on('loadingOverlay', (data) => {
         this.loading = data;
      });
      // console.log('log baruch do i debug?', process.env.VUE_APP_IS_DEBUG);
   },
   methods: {
      preventDefault(e) {
         e.preventDefault();
      },
   },
};
</script>

<style>
</style>
